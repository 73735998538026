define("discourse/plugins/discourse-chatbot/discourse/components/composer-raiser", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/models/composer", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _composer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isBotConversation}}
    <div {{did-insert this.raiseComposer}} />
  {{/if}}
  */
  {
    "id": "WPO4Rnfg",
    "block": "[[[41,[30,0,[\"isBotConversation\"]],[[[1,\"  \"],[11,0],[4,[38,1],[[30,0,[\"raiseComposer\"]]],null],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"did-insert\"]]",
    "moduleName": "discourse/plugins/discourse-chatbot/discourse/components/composer-raiser.hbs",
    "isStrictMode": false
  });
  let ComposerRaiserCompopnent = _exports.default = (_class = class ComposerRaiserCompopnent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "site", _descriptor3, this);
      _initializerDefineProperty(this, "composer", _descriptor4, this);
      _defineProperty(this, "BOT_USER_ID", -4);
    }
    raiseComposer() {
      if (!(this.site.mobileView && this.currentUser.custom_fields.chatbot_user_prefs_disable_quickchat_pm_composer_popup_mobile) && this.args.model.current_post_number === 1) {
        this.composer.focusComposer({
          fallbackToNewTopic: true,
          openOpts: {
            action: _composer.default.REPLY,
            recipients: this.siteSettings.chatbot_bot_user,
            draftKey: this.args.model.get("draft_key"),
            topic: this.args.model,
            hasGroups: false,
            warningsDisabled: true
          }
        });
      }
    }
    get isBotConversation() {
      return this.currentUser && this.args.model.archetype === "private_message" && this.args.model.user_id === this.BOT_USER_ID;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "composer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "raiseComposer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "raiseComposer"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ComposerRaiserCompopnent);
});